import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCogs, faFire, faMobile } from "@fortawesome/free-solid-svg-icons"
import { graphql } from 'gatsby'

class ServicesPage extends React.Component {
  render() {
    return (
      <Layout container={false}>
        <SEO title="Webseiten und Imagefilm | Unsere Services" />
        <div className="page-header" style={{textShadow:"0px 1px 2px #0004"}}>
          <h1 className="text-center mt-4 mb-1">Services</h1>
          <p className="text-center">Damit unterstützen wir Sie:</p>
        </div>

        <img style={{ width: "100%", zIndex: -1001, position: "absolute" }} src={require("../images/divider/divider.svg")}></img>

        <Container className="service-options-wrapper">
          <Row className="service-options">
            <Col sm={6}>
              <Link to="/services/webseite" className={"text-center"}>
                <h2 className="text-center">Webseite & Shop</h2>
                {
                  this.props.data !== undefined &&
                  <Img fluid={this.props.data.website.childImageSharp.fluid} />
                }
                <p className="text-center">Bessere Webseiten Erlebnisse.</p>
                <a className={"btn btn-primary-outline text-center mb-3"}>Mehr erfahren ›</a>
              </Link>
            </Col>
            <Col sm={6}>
              <Link to="/services/imagefilm" className={"text-center"}>
                <h2 className="text-center">Imagefilm & Produktvideo</h2>
                {
                  this.props.data !== undefined &&
                  <Img fluid={this.props.data.imagefilm.childImageSharp.fluid} />
                }
                <p className="text-center">Taschen Kino auf Social Media.</p>
                <a className={"btn btn-primary-outline text-center mb-3"}>Mehr erfahren ›</a>
              </Link>
            </Col>
          </Row>
        </Container>
        <br /> <br />
        <div className="services-divider" style={{ background: "#E2E2E2" }}>
          <div className="container" style={{ height: "130px" }}>
            <h1 style={{ textAlign: "center", left: 0, marginTop: 11, right: 0, position: "absolute" }}>Weitere Angebote</h1>
            <img style={{ display: "block", margin: "auto" }} src={require("../images/divider/divider_services.svg")} />
          </div>
          <Container>
            <Row className="nomargin-row services-other text-center" style={{marginBottom:"30px"}}>
              <Col sm={4}>
                <Link to="/services/relaunch">
                  <FontAwesomeIcon icon={faFire}></FontAwesomeIcon>
                  <h2>Webseiten Relaunch</h2>
                  <a className={"btn btn-primary-outline mb-2"}>Mehr erfahren ›</a>
                </Link>
              </Col>
              <Col sm={4}>
                <Link to="/services/apps">
                  <div style={{ marginTop: 90, marginLeft: 40, fontSize: ".7em" }} className="badge badge-success-outline">JETZT NEU !!!</div>
                  <FontAwesomeIcon icon={faMobile}></FontAwesomeIcon>
                  <h2>App Entwicklung</h2>
                  <a className={"btn btn-primary-outline mb-2"}>Mehr erfahren ›</a>
                </Link>
              </Col>
              <Col sm={4}>
                <Link to="/services/custom-software">
                  <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                  <h2>Software Entwicklung</h2>
                  <a className={"btn btn-primary-outline mb-2"}>Mehr erfahren ›</a>
                </Link>
              </Col>
            </Row>
          </Container>
        </div >
      </Layout >
    )
  }
}

export const pageQuery = graphql`
   query {
     imagefilm: file(relativePath: { eq: "services/imagefilm.jpg" }) {
       childImageSharp {
         fluid(maxWidth: 1200, maxHeight: 700) {
           ...GatsbyImageSharpFluid
         }
       }
     }
     website: file(relativePath: { eq: "services/website.jpg" }) {
       childImageSharp {
         fluid(maxWidth: 1200, maxHeight: 700) {
           ...GatsbyImageSharpFluid
         }
       }
     }
   }`

export default ServicesPage
